<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1"> 智慧招商 </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8"  md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2"> 智能招商管理平台 </b-card-title>
          <b-card-text class="mb-2"> 请登录系统, 开始你的智能招商之旅! 👋 </b-card-text>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}" v-if="loginType === 1">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <b-form-group label="账号" label-for="login-email">
                <validation-provider #default="{ errors }" name="账号" rules="required">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false:null" name="login-email"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">密码</label>
                </div>
                <validation-provider #default="{ errors }" name="密码" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false:null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="密码"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid"> 登录 </b-button>
            </b-form>
          </validation-observer>

          <!-- <div class="d-flex justify-content-center">
            <div id="weixincode" v-show="loginType === 2"></div>
          </div> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text"> 或 </div>
            <b-button v-if="loginType === 1" @click="weixin" class="mt-1" variant="primary" block> 企微授权登录 </b-button>
            <b-button v-if="loginType === 2" @click="loginType = 1" class="mt-1" variant="primary" block> 账号密码登录 </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { VBTooltip } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { login, qylogin } from '@api/account'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      wwLogin: null,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      email,
      loginType: 1,
    }
  },
  mounted: function() {
    if (this.$route.query.code) { 
      this.useWeiXinLogin(this.$route.query.code) 
    } else {
      // this.weixin()
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          login({
            userName: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data

              useJwt.setToken(response.data.accessToken)
              useJwt.setTokenTime(response.data.accessTokenExpiresAt)
              useJwt.setRefreshToken(response.data.refreshToken)
              useJwt.setRefreshTokenTime(response.data.refreshTokenExpiresAt)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              userData.role_one = userData.role[0]
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `欢迎 ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `您成功使用 ${userData.role_one.name} 身份登录, 现在你可以开始探索了!`,
                    },
                  })
                })
            })
            .catch(error => {
              // this.$refs.loginForm.setErrors(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '登录失败',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.msg,
                },
              })
            })
        }
      })
    },
    weixin: function() {
      this.loginType = 2
      this.wwLogin = new WwLogin({
        id: "weixincode",  
        // appid: "ww1a0f58d21c2e043c",
        appid: "ww14ea3e754e4d6d0c",
        // agentid: "1000004",
        agentid: "1000015",
        redirect_uri: "https%3A%2F%2Fbusiness.bjstarfish.com%2Fadmin%2Flogin",
        // redirect_uri: "https%3A%2F%2Fbeilun.myyll.com%2Fadmin%2Flogin",
        state: "wx_usl",
        href: "",
        lang: "zh",
      })
    },
    useWeiXinLogin: function(code) {
      qylogin({ jsCode: code })
        .then(response => {
          const { userData } = response.data
          useJwt.setToken(response.data.accessToken)
          useJwt.setTokenTime(response.data.accessTokenExpiresAt)
          useJwt.setRefreshToken(response.data.refreshToken)
          useJwt.setRefreshTokenTime(response.data.refreshTokenExpiresAt)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          userData.role_one = userData.role[0]
          this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `欢迎 ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `您成功使用 ${userData.role_one.name} 身份登录, 现在你可以开始探索了!`,
                },
              })
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '企微授权登录失败',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: error.msg,
            },
          })
        })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
